//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from "@/layouts/Base";
import Leaderboard from "@/components/Leaderboard";
import { alb_service } from "@/services";
import settings from "@/settings";
export default {
  name: "audience-leaderboard-page",
  components: {
    BaseLayout,
    Leaderboard,
  },
  metaInfo: {
    title: "Audience Leaderboard",
  },
  data() {
    return {
      users: [],
      fetch_size: settings.PAGE_SIZE,
      loading: false,
      count: undefined,
    };
  },
  computed: {
    last_updated_txt() {
      return "Ranks are calculated daily";
    },
  },
  mounted() {
    this.load_users();
  },
  methods: {
    load_users() {
      if (this.loading) return;
      if (this.users.length >= this.count) return;
      this.loading = true;
      alb_service
        .get({
          offset: this.users.length,
          limit: this.fetch_size,
        })
        .then((data) => {
          this.users.push(...data.results);
          this.loading = false;
          this.count = data.count;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    on_user_click(user) {
      this.$router.push({ name: "profile", params: { id: user.id } });
    },
  },
};
